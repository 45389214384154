@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./animations.scss");
@import url("https://fonts.googleapis.com/css?family=Bebas+Neue:regular");
@import url("https://fonts.googleapis.com/css?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css?family=Livvic:thin,regular,medium,semibold,bold,italic&subset=latin,latin-ext");

body {
  background-color: #1e242f;
  color: #d8c9b8;
  margin: 0;
  line-height: 120%;
  font-family: "Livvic", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  color-scheme: dark;
}

mark {
  background-color: rgba(185, 121, 169, 0.5);
  color: currentColor;
}

a {
  color: #d8c9b8;
  cursor: pointer;
}

h1 {
  color: #d8c9b8;
  margin: 0;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: bold;
  font-family: "Livvic", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

h2 {
  color: #d8c9b8;
  margin: 0;
  font-size: 2rem;
  line-height: 120%;
  font-weight: bold;
  font-family: "Livvic", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

h3 {
  color: #171e2a;
  margin: 0;
  font-size: 2rem;
  line-height: 120%;
  font-family: "Righteous", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h4 {
  margin: 0;
  font-size: 1.75rem;
  line-height: 120%;
  line-height: 1;
  font-family: "Righteous", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h5 {
  margin: 0;
  font-size: 1.5rem;
  line-height: 1;
  font-family: "Livvic", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h6 {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1;
  font-family: "Livvic", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span {
  margin: 0;
  font-family: "Livvic", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div::-webkit-scrollbar {
  display: none;
}

hr {
  border-color: #d8c9b8;
}

i {
  aspect-ratio: 1 / 1;
  display: inline-flex !important;
  width: min-content;
  height: min-content;
  line-height: 1 !important;
  vertical-align: middle;
}

label:has(~ div > input:invalid) {
  color: #f45b69;
}
label:has(~ div > input:focus) {
  color: #b979a9;
}
span:has(~ div > input:invalid) {
  opacity: 100%;
}

label:has(~ div > select:invalid) {
  color: #f45b69;
}
label:has(~ div > select:focus) {
  color: #b979a9;
}
span:has(~ div > select:invalid) {
  opacity: 100%;
}

label:has(~ textarea:focus) {
  color: #b979a9;
}
label:has(~ textarea:invalid) {
  color: #f45b69;
}

.search-bar:has(> input:focus) {
  border-color: #b979a9;
  color: #6b385f;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.05);
}

@layer components {
  /* Required by Prefabs folder ---- */
  .btn {
    @apply font-semibold transition-all duration-75 ease-in-out flex w-fit items-center space-x-2 rounded-lg cursor-pointer focus:ring-0 px-6 py-2.5 disabled:pointer-events-none disabled:opacity-70;
  }
  .icon-btn {
    @apply rounded-xl transition-all duration-75 ease-in-out cursor-pointer focus:outline-none focus:ring-0 p-2 disabled:pointer-events-none disabled:opacity-70;
  }
  .card {
    @apply relative rounded-3xl p-5;
  }
  .input {
    @apply bg-transparent rounded-t-lg border-text-light focus:border-secondary-color invalid:border-error-color placeholder:text-current placeholder:opacity-50 placeholder:italic transition-all duration-75 focus:outline-0 focus:ring-1;
  }
  .dialog-panel {
    @apply relative mx-auto max-h-screen flex flex-col transform bg-background-color rounded-3xl overflow-hidden shadow-xl transition-all sm:mt-10 sm:mb-4 sm:w-full sm:max-w-4xl;
  }
  .file-input {
    @apply relative file:bg-primary-color file:text-primary-on file:text-sm file:font-semibold file:m-0 file:border-0 file:absolute file:cursor-pointer file:right-10 file:top-0.5 file:px-4 file:py-2.5;
  }

  /* Personalized ---- */
  .toast-card {
    @apply rounded-3xl shadow-xl p-5 w-full max-w-3xl flex justify-between font-medium items-center mx-auto;
  }
  .quote-xl {
    @apply italic text-xl md:text-2xl lg:text-3xl font-thin;
  }
  .quote-base {
    @apply italic text-base font-semibold;
  }
}
